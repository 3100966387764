import React, { Component } from 'react';
import Slider from 'react-slick';

import ProductImage from '../../ProductImage';
import ProductImageMap from './ProductImageMap';
import Tag from '../../../_common/Tag';
import { getPimValue } from '../../../../utils/utils';
import { PrevAngle, NextAngle } from './Arrows';

class ProductImages extends Component {
  constructor(props) {
    super(props);

    this.mapSkuToIndex = this.mapSkuToIndex.bind(this);
  }

  mapSkuToIndex(images) {
    const { product, site } = this.props;

    const skuImageUrlMap = images.reduce((acc, image) => {
      product.variants.forEach((variant) => {
        variant.images.forEach((variantImage) => {
          if (
            variantImage.url === image.url &&
            !acc[getPimValue(variant.sku, site)]
          ) {
            acc[getPimValue(variant.sku, site)] = image.url;
          }
        });
      });

      return acc;
    }, {});

    const skuIndexMap = Object.entries(skuImageUrlMap).reduce(
      (acc, [sku, imageUrl]) => {
        acc[sku] = images.findIndex((image) => image.url === imageUrl);
        return acc;
      },
      {}
    );

    return skuIndexMap;
  }

  render() {
    const { product, imageMapProducts, onLoad, productImagesRef, site } =
      this.props;

    const images = product.images.sort((a, b) => (a.name > b.name ? 1 : -1));
    const skuIndexMap = this.mapSkuToIndex(images);

    const settings = {
      asNavFor: this.productImagesPreviewRef,
      className: 'product__images',
      dots: false,
      infinite: false,
      lazyLoad: true,
      speed: 200,
    };

    const previewSettings = {
      asNavFor: productImagesRef,
      className: 'product__images-preview',
      dots: false,
      focusOnSelect: true,
      infinite: false,
      lazyLoad: false,
      nextArrow: <NextAngle />,
      prevArrow: <PrevAngle />,
      slidesToScroll: 1,
      slidesToShow: 4,
      vertical: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            nextArrow: null,
            prevArrow: null,
            slidesToScroll: 8,
            slidesToShow: 8,
            vertical: false,
            verticalSwiping: true,
          },
        },
      ],
    };

    return (
      <>
        <Slider ref={(ref) => onLoad(ref, skuIndexMap)} {...settings}>
          {images.map((image) => (
            <ProductImage
              key={image.url}
              image={image}
              imageMapProducts={imageMapProducts}
              sizes={[
                { query: '(max-width: 479px)', containerSize: '424px' },
                { query: '(max-width: 767px)', containerSize: '712px' },
                { query: '', containerSize: '559px' },
              ]}
            >
              {product.badge?.value?.en !== 'None' && (
                <Tag title={product.badge?.value?.en} />
              )}
              {Array.isArray(image.imageMap?.spots) && (
                <ProductImageMap
                  imageMap={image.imageMap}
                  imageMapProducts={imageMapProducts}
                  site={site}
                />
              )}
            </ProductImage>
          ))}
        </Slider>
        <Slider
          ref={(ref) => (this.productImagesPreviewRef = ref)}
          {...previewSettings}
        >
          {images.map((image) => (
            <ProductImage
              key={image.url}
              image={image}
              sizes={[{ query: '', containerSize: '150px' }]}
            />
          ))}
        </Slider>
      </>
    );
  }
}

export default ProductImages;
