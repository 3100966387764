import React, { Component } from 'react';
import Slider from 'react-slick';

import anatomyIconsMapping from './anatomyIconsMapping';
import { getPimValue } from '../../../../utils/utils';
import { NextArrow, PrevArrow } from '../images/Arrows';

class ProductAnatomyIcons extends Component {
  render() {
    const { product, site } = this.props;

    const needlePoints = ['AlphaPoint', 'UltraPoint'];

    const icons = [];

    for (const iconKey of Object.keys(anatomyIconsMapping)) {
      const value = product[iconKey];

      if (!value?.value) continue;
      if (!anatomyIconsMapping[iconKey][value.key]) continue;

      const iconSet = anatomyIconsMapping[iconKey][value.key].icon.filter(
        (icon) => icon
      );

      if (iconSet.length === 0) continue;

      icons.push(
        ...iconSet.map((icon, i) => (
          <li className="anatomy-icon" key={iconKey}>
            <div className="anatomy-icon-icon-wrapper">
              <span className="anatomy-icon-icon" key={`${iconKey}-${i}`}>
                {icon}
              </span>
            </div>
            <span className="anatomy-icon-description">
              {i > 0 && needlePoints.includes(value.key)
                ? 'Needle Point'
                : getPimValue(product[iconKey], site)}
            </span>
          </li>
        ))
      );
    }

    if (icons.length === 0) return null;

    const settings = {
      className: 'anatomy-icons-list',
      dots: false,
      infinite: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      slidesToScroll: 8,
      slidesToShow: 8,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToScroll: 3,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 5,
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 6,
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToScroll: 7,
            slidesToShow: 7,
          },
        },
      ],
    };

    return (
      <section className="product__anatomy-icons">
        <div className="anatomy-icons-wrapper">
          <Slider {...settings}>{icons}</Slider>
        </div>
      </section>
    );
  }
}

export default ProductAnatomyIcons;
