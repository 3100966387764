import React from 'react';

export const AlphaSpring = (
  <svg
    version="1.1"
    id="Layer_2_00000130633893941131516930000007748583923815050119_"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.4 28.4"
    style={{
      enableBackground: 'new 0 0 28.4 28.4',
    }}
    xmlSpace="preserve"
  >
    <path
      class="st0"
      d="M21.1,5.4v1.8h0.4V5.7l0.5,1.4h0.3l0.5-1.4v1.4h0.4V5.4h-0.6l-0.4,1.2l-0.4-1.2C21.7,5.4,21.1,5.4,21.1,5.4z
	 M19.3,5.4v0.3h0.5v1.5h0.4V5.7h0.5V5.4C20.8,5.4,19.3,5.4,19.3,5.4z"
    />
    <g>
      <defs>
        <rect id="SVGID_1_" width="28.4" height="28.4" />
      </defs>

      <clipPath id="SVGID_00000045580062124442294140000007133610944566550455_">
        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
      </clipPath>

      <g
        style={{
          clipPath:
            'url(#SVGID_00000045580062124442294140000007133610944566550455_)',
        }}
      >
        <path
          class="st0"
          d="M17.5,20.9c-0.3,0.2-0.7,0.3-1.1,0.5c0.5,0.2,1.1,0.5,1.6,1c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.3,0.2,0.5,0.2
			c0.3-0.1,0.4-0.5,0.2-0.7C18.9,21.9,18.4,21.3,17.5,20.9"
        />
        <path
          class="st0"
          d="M19.5,8.6V14c0,0.7-0.9,1.1-3.4,1.9c-0.2,0.1-0.4,0.1-0.7,0.2c-0.2,0-0.3,0.1-0.5,0.2
			c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c0,0,0,0,0,0c-1,0.3-1.9,0.6-2.5,0.9
			c-0.8,0.4-0.8,0.6-0.8,0.7c0,0,0.1,0.2,1,0.6c0.8,0.3,1.9,0.5,3,0.8c0.4,0.1,0.9,0.2,1.3,0.3c-0.4,0.2-1,0.4-1.6,0.6
			c-1.2-0.3-2.2-0.5-3.1-0.8c-0.8-0.3-1.6-0.7-1.6-1.5s0.8-1.2,1.4-1.5c0.4-0.2,0.9-0.4,1.4-0.5c0.2-0.1,0.4-0.1,0.6-0.2
			c0.2-0.1,0.4-0.1,0.6-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0,0,0,0,0.1,0c0.2-0.1,0.4-0.1,0.6-0.2c0,0,0.1,0,0.1,0
			c0.9-0.3,1.9-0.6,2.7-0.9c0.9-0.3,1.2-0.5,1.3-0.6V8.6c0,0,0-0.2-0.1-0.4C18.4,8.2,18.2,8,17.9,8h-1.1c-0.2,0-0.3-0.1-0.4-0.3
			c0-0.1-0.5-0.8-2.2-0.8S12,7.7,11.9,7.8C11.8,7.9,11.7,8,11.5,8h-1.1C10.1,8,10,8.2,9.9,8.3C9.8,8.4,9.8,8.6,9.8,8.6v4.2
			c0,0.3,0,0.6,0.1,0.7c0,0.1,0,0.1,0.1,0.2c0.1,0.1,0.3,0.3,0.6,0.5c0.1,0,0.1,0.1,0.2,0.1c0.3,0.2,1.3,0.6,2.4,0.9
			c-0.1,0-0.2,0.1-0.4,0.1c-0.4,0.1-0.8,0.2-1.1,0.4c-0.6-0.2-1.2-0.5-1.4-0.6c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.3-0.7-0.5-0.9-0.8
			C9,14,8.9,13.8,8.9,13.6c0-0.2,0-0.5,0-0.8V8.6c0,0,0-0.4,0.2-0.8c0.3-0.5,0.7-0.8,1.4-0.8h0.8c0.1-0.1,0.3-0.3,0.5-0.5
			C12.3,6.2,13.1,6,14.1,6s1.8,0.2,2.4,0.6c0.2,0.2,0.4,0.3,0.5,0.5h0.8c0.6,0,1.1,0.3,1.4,0.8C19.4,8.2,19.4,8.6,19.5,8.6"
        />
        <path
          class="st0"
          d="M18.7,19.7c-0.3,0.2-0.7,0.4-1.2,0.7c-0.1,0.1-0.3,0.1-0.5,0.2c-0.4,0.2-0.8,0.3-1.2,0.5
			c-0.2,0.1-0.4,0.2-0.6,0.2c-1.3,0.5-2.9,1-4.8,1.6c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.5,0
			c-0.3-0.2-0.3-0.6,0-0.8c0.1-0.1,0.2-0.1,0.4-0.2c0,0,0.1,0,0.3-0.1c1.3-0.4,2.5-0.8,3.4-1.1l0,0c0.2-0.1,0.5-0.2,0.7-0.2
			c0.6-0.2,1.1-0.4,1.5-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.9-0.4,1.5-0.7,1.8-0.9c0.1-0.1,0.3-0.3,0.3-0.4c-0.1-0.4-1.1-0.9-1.7-1.1
			c-0.2-0.1-1.1-0.3-2.2-0.7c0.2,0,0.3-0.1,0.5-0.2h0c0.4-0.1,0.7-0.2,1.1-0.3l0,0c0.3,0.1,1.4,0.4,2,0.7c0.8,0.4,1.2,0.9,1.3,1.4
			C19.5,19,19.1,19.4,18.7,19.7"
        />
      </g>
    </g>
  </svg>
);

export const AP = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.72 28.72">
    <defs>
      <style>{'.cls-2{fill:#ce0e2d}'}</style>
    </defs>
    <g>
      <rect width={28.72} height={28.72} rx={3.48} fill="none" />
      <path
        className="cls-2"
        d="M24.61 11.72a1.32 1.32 0 010-2.64 1.32 1.32 0 010 2.64m-1.05-1.32a1.09 1.09 0 001.05 1.12 1.12 1.12 0 000-2.24 1.09 1.09 0 00-1.05 1.12"
      />
      <path
        className="cls-2"
        d="M25.19 11.17H25a.84.84 0 01-.1-.38.28.28 0 00-.32-.26h-.16v.64h-.23V9.69a3.88 3.88 0 01.41 0 .77.77 0 01.45.11.34.34 0 01.14.3.37.37 0 01-.29.36c.13 0 .22.15.25.36a1.3 1.3 0 00.09.37m-.82-.82h.17c.19 0 .35-.07.35-.25s-.09-.26-.35-.26h-.17v.5M10.55 9.08l4.17 10.56h-3.31l-.73-2.12H6.45l-.72 2.12H2.81L7 9.08zm-2 2.06l-1.41 4.43H10l-1.36-4.43zM18.39 9.08c2.65 0 4 1 4 3s-1.43 3-4.06 3h-1.91v4.59h-1V9.08zm.41 5.09c1.67 0 2.61-.7 2.61-2.11S20.52 10 18.72 10h-2.3v4.21z"
      />
    </g>
  </svg>
);

export const UP = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.72 28.72">
    <defs>
      <style>{'.cls-2{fill:#70d44b}'}</style>
    </defs>
    <g>
      <rect width={28.72} height={28.72} rx={3.48} fill="none" />
      <path
        className="cls-2"
        d="M24 11.72a1.32 1.32 0 010-2.64 1.32 1.32 0 010 2.64m-1-1.32a1.09 1.09 0 001 1.12 1.12 1.12 0 000-2.24 1.09 1.09 0 00-1 1.12"
      />
      <path
        className="cls-2"
        d="M24.57 11.17h-.25a1 1 0 01-.1-.38.27.27 0 00-.31-.26h-.17v.64h-.23V9.69a4.1 4.1 0 01.42 0 .79.79 0 01.45.11.33.33 0 01.13.3.36.36 0 01-.28.36c.12 0 .21.15.24.36a1.16 1.16 0 00.1.37m-.83-.82h.18c.18 0 .35-.07.35-.25s-.1-.26-.35-.26h-.18v.5M6.31 9.08v6.16a2.87 2.87 0 00.2 1.26 2 2 0 001.88 1.09c1.37 0 2-.83 2-2.47v-6h2.79v6c0 1.77-.33 3-1.59 3.81a6.29 6.29 0 01-3.35.78c-2 0-3.7-.48-4.46-2.1a5.42 5.42 0 01-.4-2.3V9.08zM17.71 9.08c2.58 0 3.93 1 3.93 2.91s-1.4 2.9-4 2.9h-1.9v4.48h-.93V9.08zm.39 5c1.63 0 2.54-.69 2.54-2S19.77 9.93 18 9.93h-2.22V14z"
      />
    </g>
  </svg>
);

export const HookEyeTurnDownEye = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M16.4,10l-5.5-1.9c-0.5-0.2-1,0.1-1.2,0.6c0,0,0,0,0,0L9.5,9.1c-0.2,0.5,0.1,1,0.6,1.2l5.1,1.8v8.6h1.6v-9.1
			l0,0l0.1-0.3C17.1,10.7,16.8,10.2,16.4,10C16.4,10,16.4,10,16.4,10z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeTurnUpEye = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M20.4,9.1l-0.1-0.4c-0.2-0.5-0.7-0.7-1.2-0.6L13.6,10c-0.5,0.2-0.7,0.7-0.6,1.2l0.1,0.3l0,0v9.1h1.6v-8.6
			l5.1-1.8C20.3,10.1,20.6,9.6,20.4,9.1z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeActionRing = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M19.8,13.7c-1.1-0.2-2.3-0.3-3.4-0.1c-0.2-0.8-0.6-1.5-1.1-2.1c-1.7-1.7-4.4-1.7-6,0
			c-0.5,0.5-0.9,1.1-1.1,1.8c-0.1,0.2-0.3,0.5-0.5,0.5H3.8v1.2h4c0.8-0.2,1.4-0.8,1.6-1.5c0.2-0.5,0.5-0.9,0.9-1.3
			c0.6-0.5,1.3-0.7,2.1-0.7c0.8,0,1.5,0.4,2,0.9c0.4,0.4,0.7,1,0.8,1.6C14.6,14,14.1,14.4,14,15c-0.1,0.5,0.1,0.9,0.4,1.3
			c-0.5,0.5-1.2,0.9-2,0.9c-0.8,0-1.6-0.3-2.2-0.8C9.8,16,9.5,15.6,9.3,15l-0.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c0.2,0.6,0.5,1.2,1,1.6
			c0.8,0.8,2,1.3,3.1,1.2c1.2,0,2.3-0.4,3.1-1.2l0.1-0.1c1.1,0.6,2.3,1,3.6,1.2c0.8,0.1,1.5,0.2,2.3,0.2c0.5,0,1,0,1.4-0.1
			c1.6-0.3,2.1-1,2.1-1.5C25.1,15.4,22.5,14.1,19.8,13.7z M19.2,17.1c-1.1-0.2-2.1-0.5-3-1c0.2-0.4,0.3-0.8,0.3-1.2
			c0.4,0,0.7-0.1,1.1-0.1c0.7,0,1.3,0.1,2,0.2c2.6,0.5,3.9,1.5,3.9,1.8S21.8,17.6,19.2,17.1z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeBrazed = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M17.1,9.5c-2.2-0.1-4.2,1.2-5,3.2c-0.1,0.4-0.6,0.7-1,0.7H7v1.8h4.1c0.5,0,0.9,0.3,1,0.7
			c1,2.6,3.8,3.9,6.4,2.9c1.9-0.7,3.2-2.5,3.2-4.5C21.7,11.8,19.7,9.7,17.1,9.5z M17.4,17.5c-1.7,0.3-3.4-0.8-3.7-2.5
			c-0.3-1.7,0.8-3.4,2.5-3.7c1.7-0.3,3.4,0.8,3.7,2.5c0.1,0.4,0.1,0.8,0,1.2C19.6,16.3,18.6,17.3,17.4,17.5L17.4,17.5z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeLooped = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M18.3,10.4c-1.5,0.1-3.1,0.5-4.5,1.1c-1.6,0.7-3.3,1-5,1.1c-0.5,0-1,0-2.3-0.1v3.9c1.3-0.1,1.8-0.1,2.4-0.1
			c1.7,0.1,3.3,0.4,4.9,1.1c1.4,0.6,3,1,4.5,1.1c0.5,0,0.9,0,1.4-0.1c3.9-0.7,4-6.7,0.3-7.6C19.4,10.4,18.8,10.4,18.3,10.4
			 M18.3,16.3c-2-0.1-3.9-0.8-5.4-2c1.6-1.2,3.4-1.8,5.4-1.9c1.3,0,2.3,0.5,2.3,2S19.6,16.3,18.3,16.3"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeNeedleEye = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M17.1,16.9c-1.1,0-2.1-0.2-3.2-0.5c-0.5-0.1-1-0.3-1.4-0.5H5.4v-3.3h7.2c0.5-0.2,0.9-0.3,1.4-0.5
			c1-0.3,2.1-0.5,3.1-0.5c1,0,2.1,0.2,3.1,0.5c0.5,0.1,0.9,0.3,1.4,0.5h1.8v3.3h-1.7c-0.5,0.2-0.9,0.4-1.4,0.5
			C19.2,16.8,18.2,17,17.1,16.9L17.1,16.9z M13.1,14.4c0.1,0.4,0.5,0.7,1.1,1c0.9,0.3,1.9,0.4,2.8,0.4c1,0,2-0.1,2.9-0.4
			c0.5-0.2,1.2-0.5,1.2-1.1s-0.4-0.8-1.2-1.1c-0.9-0.3-1.9-0.5-2.8-0.4c-1,0-1.9,0.1-2.9,0.4c-0.7,0.3-1.1,0.6-1.1,1l0,0L13.1,14.4
			L13.1,14.4z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeOpenRing = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M16.6,9.4c-0.9,0-1.8,0.2-2.6,0.7c-0.8,0.4-1.4,1.1-1.8,1.9c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.3,0.6
			l-0.2,0.2c-0.1,0.1-0.3,0.1-0.4,0.1H7.1V15h4.1c1,0,2-0.6,2.4-1.5c0.1-0.2,0.1-0.4,0.2-0.5l0.1-0.1l0,0l0,0l0,0l0,0
			c0.2-0.5,0.6-0.9,1-1.1c0.5-0.3,1.1-0.4,1.6-0.4c1.7-0.1,3.1,1.3,3.2,3c0.1,1.7-1.3,3.1-3,3.2c-1.1,0-2.2-0.5-2.7-1.5l-1.6,1
			c1.5,2.3,4.5,3.1,6.9,1.6s3.1-4.5,1.6-6.9C20,10.2,18.3,9.3,16.6,9.4"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeOpenShank = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M20,10.3c-3.5-2.6-6.6,1.1-10.1,1.1H6.7v2c1.5-0.1,3.2-0.2,5.1-0.4c2.3-0.3,4.3-2.4,6.6-1.3
			c0.9,0.6,1.5,1.5,1.6,2.6c-0.1,1-0.5,1.9-1.3,2.4c-0.6,0.6-1.5,0.8-2.3,0.6C14.1,16.5,12,15,9.3,15l-2.6-0.1v1.9
			c1.5,0.1,3,0.2,4.5,0.3c2.3,0.7,4.1,2.5,6.7,2c1.9-0.5,3.4-2,3.9-3.9C22.3,13.4,21.5,11.5,20,10.3z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeRinged = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M21.9,14.5c0,1.3-0.5,2.5-1.4,3.4c-1,1-2.3,1.5-3.7,1.5c-1.4,0-2.7-0.5-3.7-1.5c-0.4-0.4-0.8-0.9-1-1.4
			c0.6-0.2,1.2-0.6,1.7-1c0.2,0.4,0.4,0.7,0.8,1c0.6,0.6,1.5,0.9,2.3,0.9c1.6,0.1,3-1.1,3.1-2.7c0,0,0-0.1,0-0.1
			c0-1.7-1.4-3.1-3.1-3.1c-0.8,0-1.6,0.3-2.2,0.9c-0.4,0.4-0.6,0.9-0.8,1.4c-0.1,0.4-0.4,0.7-0.7,1L12.8,15
			c-0.4,0.3-0.8,0.4-1.3,0.4H6.8v-1.9h4.5c0.3,0,0.6-0.3,0.6-0.6c0.6-2.2,2.6-3.7,4.9-3.7C19.6,9.4,21.9,11.6,21.9,14.5"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeSpade = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M23.2,11.3c-0.1-0.1-0.3-0.2-0.5-0.2H15c-0.2,0-0.4,0.1-0.6,0.3l-0.9,1.2H5.4V14l0,0v0.6l0,0v1.3h8.1l1,1.3
			c0.1,0.2,0.3,0.3,0.6,0.3h7.7c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-5.1C23.4,11.7,23.3,11.5,23.2,11.3z"
        />
      </g>
    </g>
  </svg>
);
export const HookEyeTaperedRing = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M16.8,9.4c-2.2,0-4.2,1.5-4.8,3.6c-0.1,0.3-0.3,0.5-0.6,0.6H6.8v1.9h4.7c1-0.1,1.9-0.7,2.3-1.7
			c0.2-0.5,0.5-1,0.8-1.4c1.2-1.2,3.2-1.2,4.4,0c0.6,0.6,0.9,1.4,0.9,2.2c-0.1,1.7-1.3,3-3,3.3c-0.9,0.1-1.8-0.2-2.4-0.8
			c-0.5-0.4-0.9-0.9-1.3-1.4l-0.4,0.2c0,0.5,0.2,1,0.5,1.5c0.8,1.3,2.1,2.1,3.6,2.1l0,0c1.4,0,2.6-0.5,3.6-1.5
			c0.9-0.9,1.4-2.1,1.4-3.4C21.9,11.7,19.6,9.4,16.8,9.4"
        />
      </g>
    </g>
  </svg>
);
export const HookLengthALLLong = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon
          points="21.8,14.3 16.8,19.3 16.8,16.6 11.9,16.6 11.9,19.3 6.9,14.3 11.9,9.4 11.9,12.1 16.8,12.1 16.8,9.4
					"
        />
      </g>
    </g>
  </svg>
);
export const HookLengthALLShort = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon
          points="18.9,12.1 18.9,9.4 14.4,13.9 9.9,9.4 9.9,12.1 6.8,12.1 6.8,16.6 9.9,16.6 9.9,19.3 14.4,14.8
			18.9,19.3 18.9,16.6 22,16.6 22,12.1 		"
        />
      </g>
    </g>
  </svg>
);
export const GripPin = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M23.4,14.8v1.4c-1,0.5-2,1-3.1,1.3c-2.4,0.4-4.9,1.2-7.2-0.6c-0.5-0.3-1.1-0.5-1.7-0.4c-2-0.1-3.9,0-6,0v-1.9
			c1.7,0,3.3,0,4.9,0c0.4-0.1,0.8-0.2,1.2-0.5c-0.3-0.4-0.6-0.9-1-1.1c-0.5-0.2-1.1-0.3-1.6-0.3c-0.7-0.1-1.4-0.4-1.1-1.2
			c0.3-0.5,0.9-0.8,1.5-0.8c1.5,0.5,3,1.2,4.4,1.9c0.4,0.3,0.9,0.5,1.4,0.6c1.7,0.4,3.5,0.6,5.2,1C21.2,14.3,22.2,14.6,23.4,14.8"
        />
      </g>
    </g>
  </svg>
);
export const InLineTreble = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M21.6,7.1l-1.6,0.4l-4.1,3v-0.1c0-0.5-0.4-0.9-0.9-0.9c0,0,0,0,0,0h-1c-0.5,0-0.9,0.4-0.9,0.9c0,0,0,0,0,0
			v0.2l-4.3-3L7.1,7.2l0.9,1.4l5,3.5v2.4c0,0.4,0.3,0.8,0.8,0.9v4.8l0.6,1.6l0.5-1.6v-4.8c0.4-0.1,0.7-0.4,0.7-0.9v-2.4l4.9-3.6
			L21.6,7.1z M14.1,13.6v-2.4c0-0.2,0-0.9,0.2-0.9h0.1c0.2,0,0.2,0.7,0.2,0.9v2.4c0,0.2,0,0.8-0.2,0.8h-0.1
			C14.1,14.4,14.1,13.9,14.1,13.6z"
        />
      </g>
    </g>
  </svg>
);
export const SlowDeath = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M18.5,15.8c-0.1,0.2-0.3,0.6-0.5,0.8c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.2-0.1,0.3-0.1,0.5
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.2l-0.3,0.4c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2,0-0.4,0
			c-0.1,0-1.1,0.3-1.1,0.3c-0.2,0.1-0.4,0.2-0.6,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.5-0.2-0.9-0.4c-0.2-0.2-0.4-0.3-0.6-0.3
			c-0.3,0-0.6-0.1-0.8-0.1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.2-0.2-0.5-0.2-0.7c0.1-0.3,0.1-0.6,0-0.9
			c-0.1-0.2-0.2-0.4-0.4-0.5v-2.1c0-0.1-0.1-0.3-0.4-0.5c-0.2-0.2-0.2-0.4-0.1-0.7c0-0.1,0.1-0.2,0.1-0.2c0-0.3,0.2-0.6,0.5-0.8
			c0.3-0.2,0.5-0.4,0.7-0.7l0.4-0.3c0.2-0.1,0.5-0.2,0.7-0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.2-0.1l1.4-0.3
			c0.3-0.1,0.5-0.1,0.8-0.1l-0.8,0.6c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.1-1.2,0.8-1.2,1.2c0,0.1-0.1,0.2-0.4,0.3
			c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.2,0.1,0.3,0.1,0.5c0.1,0.1,0.1,0.3,0.1,0.5v0.9c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.2,0.3,0.2,0.5v0.4
			c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.4,0.8,0.6,0.8l0.4,0.4c0.2,0.2,0.5,0.3,0.7,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.4,0,0.6-0.1
			c0.2,0,0.3-0.1,0.4-0.2c0.2-0.2,0.5-0.3,0.7-0.5c0.4-0.2,0.8-1.1,0.8-1.1l0.1-0.3l0.1-0.2c0.1,0-0.1-1.1-0.1-1.1
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.3-0.5c0-0.1-0.4-0.6-0.3-0.7c0.1-0.2-0.5-0.8-0.6-0.8c-0.2,0-0.3,0-0.5,0
			c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0.1-0.4,0.3c-0.1,0.3-0.2,0.6-0.3,0.9
			c-0.1,0.4-0.1,0.7-0.1,1.1c0,0.3,0,0.7,0.1,1c0.1,0.3,0.3,0.6,0.5,0.7c0.1,0.1,0.3,0.2,0.5,0.1c0.2,0,0.4,0,0.7-0.1
			c0,0,0.3-0.3,0.3-0.4c0-0.2,0-0.4-0.1-0.6c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.2
			s0,0.2,0,0.3c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.4-0.1s-0.1-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c0-0.2,0.1-0.4,0.2-0.6
			c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.4,0.4,0.5,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.3,0,0.5
			c0,0.3-0.1,0.6-0.3,0.9c-0.1,0.2-0.5,0.4-0.8,0.5C14.9,17,14.7,17,14.5,17c-0.4,0-0.7-0.1-1-0.3c-0.3-0.2-0.6-0.5-0.8-0.8
			c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5
			c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3l0.6-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.4-0.3
			c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.2h0.4c0.4,0.4,0.8,0.7,1.1,1.1c0,0,0.5,0.5,0.6,0.5
			c0.5,0.4,0.9,1,1.1,1.6C18.7,14.8,18.6,15.3,18.5,15.8"
        />
      </g>
    </g>
  </svg>
);
export const SuperSlowDeath = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M14.1,14.7c0,0.2,0.8,0,0.2,0.5c-0.5,0.1-0.9,0-1.3-0.2c-0.2-0.3-0.4-0.7-0.5-1.1c-0.1-0.2-0.1-0.5-0.2-0.7
			c0-0.2,0.2-0.6,0.2-0.8c0.1-0.3,0.3-0.6,0.5-0.9l0.7-0.5c0.3-0.1,0.6-0.1,0.9-0.2c0.1,0,1.1,0.3,1.5,0.4c0.4,0.2,0.7,0.5,1,0.8
			c0,0,0.5,0.6,0.7,0.6c0.5,0.4,0.8,1.1,0.9,1.7c0,0.2-0.1,1.5-0.1,1.7c-0.1,0.2-0.5,0.6-0.6,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
			c-0.1,0.2-0.2,0.4-0.1,0.5c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.3,0.2-0.4,0.3L17,18.9c-0.1,0-0.1,0.1-0.3,0.2c-0.1,0.1-0.3,0.2-0.4,0.2
			c-0.1,0-0.3,0-0.4,0c-0.1,0-1.3,0.2-1.3,0.2c-0.2,0.1-0.5,0.1-0.8,0.1c-0.3,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.6-0.3-1-0.5
			c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.7-0.1-1-0.1c-0.1-0.1-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.1-0.1-0.8-0.1-0.8
			c0.1-0.3,0.1-0.7,0.1-1C9.2,15.4,9.1,15.2,9,15v-0.8v-1.4c0-0.4,0.1-0.7,0.2-1.1c0,0,0.1-0.7,0.1-0.8c0-0.3,0.3-0.4,0.7-0.7
			s0.3-0.4,0.5-0.6L11,9.3c0.2-0.1,0.5-0.2,0.8-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.4,0.3,0.4c-0.3,0.2-0.6,0.5-0.9,0.7
			c-0.4,0.4-0.8,0.9-1,1.5c0,0.1-0.3,0.5-0.2,0.7c0.1,0.5,0.1,0.9,0.1,1.4c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0.2,0.6,0.2,0.9
			c0,0.2,0.1,0.4,0.2,0.5c0.3,0.2,0.6,0.4,0.9,0.5l0.4,0.4c0.2,0.2,0.5,0.4,0.8,0.6l0.8,0.3c0.2,0,0.5,0,0.7,0
			c0.2,0,0.4-0.1,0.5-0.2c0.3-0.2,0.6-0.3,0.9-0.4c0.4-0.2,1-1.1,1-1.1l0.2-0.3l0.2-0.2c0.2,0,0-1.2,0-1.2c-0.1-0.2-0.2-0.3-0.2-0.5
			c0-0.2-0.1-0.4-0.3-0.6c0-0.1-0.4-0.6-0.4-0.8c0.1-0.2-0.5-0.9-0.6-0.9c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.2,0
			c-0.4-0.2-0.6,0.1-0.7,0.3s-0.4,0.2-0.5,0.7s-0.4,0.7-0.5,1c-0.1,0.4,0.2,0.8,0.6,0.8C14,14.7,14,14.7,14.1,14.7"
        />
      </g>
    </g>
  </svg>
);
export const WideGap = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="12.5,15.2 17.6,15.2 15.1,17.8 		" />
        <polygon points="17.6,13.6 12.5,13.6 15.1,11 		" />
        <path
          d="M15.4,7.2l-2.5,0L16.1,9l0.3-0.5c2.9,0.1,5.7,0.9,5.9,5.7c0.2,1.7-0.3,3.4-1.4,4.7c-0.8,0.8-1.9,1.3-3.1,1.2
			H5.3v1.3h12.4c1.5,0,3-0.6,4-1.6c1.3-1.6,2-3.6,1.8-5.7C23.4,7.1,17.8,7.2,15.4,7.2z"
        />
      </g>
    </g>
  </svg>
);
export const HookPointArrow = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="21.3,13.8 14.9,13.8 14.9,7.4 		" />
        <polygon points="14.9,21.3 14.9,14.9 21.3,14.9 		" />
        <polygon points="7.3,14.9 13.8,14.9 13.8,21.3 		" />
        <polygon points="13.8,7.4 13.8,13.8 7.4,13.8 		" />
      </g>
    </g>
  </svg>
);
export const HookPointBarbless = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path d="M20.4,12.6c-0.8,0-1.6,0-2.4,0.2c-1,0.1-13.4,3.4-13.4,3.4h19.6v-3.6h-2.8C21.3,12.6,21,12.6,20.4,12.6" />
      </g>
    </g>
  </svg>
);
export const HookPointBarblessNeedle = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M19.6,12.7c-1.5,0-2.7,0-3.1,0c-3.2,0.2-4.7,0.3-5.7,0.4c-3,0.4-6.2,1.2-6.2,1.2s3.2,0.8,6.2,1.3
			c1.9,0.2,3.8,0.3,5.7,0.3H24v-3.2L19.6,12.7"
        />
      </g>
    </g>
  </svg>
);
export const HookPointBeak = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M5.1,10.6c3,6.6,10.4,7.6,17.3,7.6h1.1v-3.5h-3.1l3.1-3c-1.8,1.8-5.5,2.5-6.6,2.8c-1,0.2-2.1,0.2-3.2,0.3
			C10.9,14.7,8.4,13.8,5.1,10.6"
        />
      </g>
    </g>
  </svg>
);
export const HookPointClassic = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="20.9,11.5 18,13.6 4.4,17.2 24.3,17.2 24.3,13.6 20,13.6 		" />
      </g>
    </g>
  </svg>
);
export const HookPointDiamond = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="14.3,13.5 17.4,8.2 11.3,8.1 		" />
        <polygon points="15.3,13.9 21.5,13.8 18.5,8.5 		" />
        <polygon points="13.4,13.9 10.3,8.5 7.2,13.8 		" />
        <polygon points="14.4,15.2 11.3,20.6 17.5,20.6 		" />
        <polygon points="13.4,14.9 7.2,14.9 10.2,20.2 		" />
        <polygon points="15.3,14.9 18.4,20.2 21.5,15 		" />
      </g>
    </g>
  </svg>
);
export const HookPointHollow = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M23.7,13.6v3.5c-4-0.1-7.9-0.7-11.7-1.9c-6.7-1.8-7.6-2.7-7.6-2.7c5.5,0.5,11,0.6,16.4-0.9l-1.6,1.5
			C20.7,13.6,22.2,13.7,23.7,13.6"
        />
      </g>
    </g>
  </svg>
);
export const HookPointKnifeEdge = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="4.8,17.4 6.3,17.4 22.2,12 22.5,11.3 		" />
        <polygon points="21.9,12.8 8.4,17.4 24,17.4 24,14.3 21.2,14.3 		" />
      </g>
    </g>
  </svg>
);
export const HookPointMicroBarb = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path d="M21.4,13.1l0.7-1.1l-11.5,3h0.1c-3.2,0.8-6.1,1.6-6.1,1.6h19.6v-3.6L21.4,13.1z" />
      </g>
    </g>
  </svg>
);
export const HookPointNeedlePoint = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <path d="M22.5 11.3s-5.1 2.5-6 2.9c-3.2.1-4.7.3-5.7.4-2.1.3-4.2.7-6.2 1.1 0 0 3.2.8 6.2 1.3 1.9.2 3.8.3 5.6.3H24v-3.2h-4.1c.8-.6 2.6-2.8 2.6-2.8" />
  </svg>
);
export const HookShankALLBaitholder = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="15.2,15.8 8.3,11.5 10.3,15.8 4.5,15.8 4.5,17.2 23.6,17.2 23.6,15.8 		" />
      </g>
    </g>
  </svg>
);
export const HookShankALLJigEyeBend = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M21.2,17.6c-0.3-1.8-2-2.9-3.8-2.6c-0.9,0.2-1.6,0.7-2.1,1.4l-0.1,0.1l-6.1-3.2v-6H7.5v6.9l7.2,3.7
			c0,0.3,0,0.6,0,0.9c0.3,1.5,1.6,2.7,3.2,2.7c0.2,0,0.4,0,0.6-0.1C20.3,21.1,21.5,19.4,21.2,17.6L21.2,17.6z M19.4,19.1
			c-0.3,0.4-0.7,0.6-1.1,0.7c-0.4,0.1-0.9,0-1.2-0.3c-0.8-0.5-1-1.6-0.4-2.4c0.3-0.4,0.7-0.6,1.1-0.7c0.1,0,0.2,0,0.3,0
			c0.9,0,1.7,0.7,1.7,1.7C19.7,18.5,19.6,18.9,19.4,19.1L19.4,19.1z"
        />
      </g>
    </g>
  </svg>
);
export const HookShankKinkShank = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M23.2,12.9c-0.2-0.5-0.7-1-1.2-1.2c-0.6-0.2-1.2-0.2-1.7,0c-0.4,0.2-0.8,0.6-1,1c-1.4-0.3-4.7-0.6-5.7,1.9
			c-0.4,1-0.8,1.2-0.9,1.2s-0.4-0.2-0.7-1.1c-0.9-3-5.9-2.2-6.5-2L5.6,14c1.1-0.2,4.5-0.4,4.9,1c0.5,1.7,1.3,2.1,1.9,2.2h0.1
			c0.6,0,1.4-0.4,2.1-2s3.1-1.2,4.2-1c0,0.2,0.1,0.5,0.2,0.7c0.2,0.5,0.7,0.9,1.2,1.1c0.5,0.2,1.2,0.2,1.7,0
			C23.2,15.5,23.7,14.1,23.2,12.9C23.2,13,23.2,12.9,23.2,12.9z M21.5,14.7c-0.2,0.1-0.5,0.1-0.7,0c-0.5-0.2-0.7-0.7-0.5-1.1
			c0,0,0,0,0,0c0.1-0.2,0.2-0.4,0.5-0.5C20.9,13,21,13,21.1,13c0.1,0,0.2,0,0.3,0.1c0.2,0.1,0.4,0.2,0.5,0.5l0,0
			C22.1,14,21.9,14.5,21.5,14.7C21.5,14.7,21.5,14.7,21.5,14.7z"
        />
      </g>
    </g>
  </svg>
);
export const HookShankOffsetShank = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M23.3,13.6c-0.1-0.5-0.4-1-0.9-1.3c-0.5-0.3-1-0.4-1.6-0.3c-0.5,0.1-1,0.4-1.3,0.9c0,0.1-0.1,0.1-0.1,0.2
			c-1.9-0.4-2.6-0.3-3.9,1.1c-0.6,0.9-1.7,1.4-2.8,1.4c-1.1-0.1-2.1-0.2-3.1-0.5l-4-0.8l-0.3,1.2l4,0.8c1.1,0.3,2.3,0.5,3.4,0.5l0,0
			c1.5,0,2.9-0.7,3.7-1.9c0.9-1,1-1.1,2.7-0.7c0,0,0,0.1,0,0.1c0.1,0.5,0.4,1,0.9,1.3c0.3,0.2,0.8,0.4,1.2,0.4c0.1,0,0.3,0,0.4,0
			C22.7,15.9,23.5,14.8,23.3,13.6C23.3,13.7,23.3,13.6,23.3,13.6z M21.4,14.8c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.3-0.3-0.5
			c-0.1-0.4,0.2-0.9,0.6-1c0,0,0,0,0.1,0h0.1c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.3,0.3,0.3,0.5C22.2,14.3,21.9,14.7,21.4,14.8
			C21.4,14.8,21.4,14.8,21.4,14.8z"
        />
      </g>
    </g>
  </svg>
);
export const HookShankZBend = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M23.3,12.5c-0.3-1.2-1.4-1.9-2.6-1.6c0,0,0,0,0,0c-0.7,0.2-1.2,0.6-1.5,1.3c-3.4-1.2-4-0.4-4.2-0.1
			c-0.6,0.8,0,1.9,0.6,2.7c0.4,0.6,0.6,1.1,0.5,1.3s-1.2,0.6-2.9,0.4C12,16.3,11,16,9.9,15.7l-4.1-1.2l-0.4,1.3l4.1,1.2
			c1.1,0.4,2.3,0.7,3.5,0.9c0.4,0,0.8,0.1,1.2,0.1c1.2,0,2.5-0.2,3-1.1s0.2-1.6-0.5-2.8c-0.3-0.3-0.5-0.7-0.6-1.1
			c0.2-0.1,1.3,0,3.1,0.7c0.3,0.9,1.1,1.6,2.1,1.6c0.2,0,0.4,0,0.5-0.1C22.9,14.8,23.6,13.7,23.3,12.5
			C23.3,12.5,23.3,12.5,23.3,12.5z M21.4,13.8c-0.4,0.1-0.9-0.2-1-0.6c-0.1-0.2,0-0.5,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4h0.2
			c0.2,0,0.3,0,0.4,0.1c0.2,0.1,0.3,0.3,0.4,0.5C22.1,13.3,21.8,13.7,21.4,13.8z"
        />
      </g>
    </g>
  </svg>
);
export const HookWireStrengthALLFine = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <rect x="6.6" y="13.5" width="15.6" height="1.8" />
        <polygon points="11.2,9.4 17.6,9.4 14.4,12.6 		" />
        <polygon points="17.6,19.3 11.2,19.3 14.4,16.1 		" />
      </g>
    </g>
  </svg>
);
export const HookWireStrengthALLStrong = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M19.7,9.4h-0.3c-1.3,0-2.4,1-2.4,2.4c0,0,0,0,0,0v1h-5.3v-1c0-1.3-1.1-2.4-2.4-2.4H9c-1.3,0-2.4,1.1-2.4,2.4
			c0,0,0,0,0,0v5.1c0,1.3,1.1,2.4,2.4,2.4h0.3c1.3,0,2.4-1.1,2.4-2.4l0,0v-1H17v1c0,1.3,1.1,2.4,2.4,2.4h0.3c1.3,0,2.4-1.1,2.4-2.4
			v-5.1C22,10.5,21,9.4,19.7,9.4z"
        />
      </g>
    </g>
  </svg>
);
export const InLine = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path d="M23.9,12.9v2.9H13.1c0,0,0-0.1,0-0.1l8.5-1.4L13.1,13c0,0,0-0.1,0-0.1L23.9,12.9z" />
        <path
          d="M15.9,14.3c0,0-3.1,0.4-5.7,0.6H9.8c-1.7,0.2-3.1,0.4-3.3,0.4c-0.5,0.1-1,0-1.4-0.2c-0.2-0.2-0.2-0.5-0.2-0.7
			c0-1.2,1.2-1,1.7-1c0.2,0,1.6,0.1,3.2,0.3h0.4L15.9,14.3z"
        />
      </g>
    </g>
  </svg>
);
export const Kirbed = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M7.3,9.6c-0.3,0-0.7,0.2-0.9,0.4c-0.9,1.6,1.7,2.6,2.5,3l12.6,6.2c0,0-10.8-8-11.5-8.4
			C9.2,10.3,8.3,9.8,7.3,9.6"
        />
        <polygon points="20.8,9.4 9.6,9.4 12.7,11.7 20.8,11.7 		" />
      </g>
    </g>
  </svg>
);
export const Reversed = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <path
          d="M7.3,19.1c-0.3,0-0.7-0.2-0.9-0.5c-0.9-1.6,1.7-2.6,2.5-3c0.8-0.4,12.6-6.2,12.6-6.2s-10.8,8-11.5,8.4
			C9.2,18.4,8.3,18.9,7.3,19.1"
        />
        <polygon points="20.8,19.3 9.6,19.3 12.7,17 20.8,17 		" />
      </g>
    </g>
  </svg>
);
export const HookWireForged = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="10.6,9.4 8.9,9.4 8.9,12.2 6,12.2 6,16.6 8.9,16.6 8.9,19.3 10.6,19.3 		" />
        <polygon points="19.8,9.4 18.1,9.4 18.1,19.3 19.8,19.3 19.8,16.6 22.8,16.6 22.8,12.2 19.8,12.2 		" />
        <path
          d="M14.4,9.4c-1-0.1-1.9,0.7-2,1.7c0,0,0,0,0,0.1v6.5c0.1,1,0.9,1.8,2,1.8c0,0,0,0,0,0c1,0.1,1.9-0.7,2-1.7
			c0,0,0,0,0,0v-6.4c0-1-0.9-1.8-1.9-1.8C14.5,9.4,14.4,9.4,14.4,9.4"
        />
      </g>
    </g>
  </svg>
);
export const HookWireTriangle = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28.7 28.7"
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <polygon points="22.6,19.3 6.1,19.3 14.4,9.4 		" />
      </g>
    </g>
  </svg>
);
