import axios from 'axios';
import React, { Component } from 'react';
import Slider from 'react-slick';

import { ArrowRight, ArrowLeft } from '../../_common/Icons';

class ProductLifestylePhotos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lifestylePhotos: [],
    };

    this.fetchLifestylePhotos = this.fetchLifestylePhotos.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.product.baseShopId !== prevProps.product.baseShopId) {
      this.fetchLifestylePhotos();
    }
  }

  async fetchLifestylePhotos() {
    const { product } = this.props;

    const pickitResponse = await axios({
      method: 'GET',
      url: `/api/pickit/files/${product.series.value}`,
    }).then((res) => res.data);

    const yotpoResponse = await axios({
      method: 'GET',
      url: `/api/yotpo/albums/product/${product.baseShopId}`,
    }).then((res) => res.data);

    const merged = [...pickitResponse, ...yotpoResponse]
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .filter((img) => img.imageSrc || img.videoSrc);

    this.setState((prevState) => ({
      ...prevState,
      lifestylePhotos: merged,
    }));
  }

  render() {
    const { lifestylePhotos } = this.state;

    if (lifestylePhotos.length === 0) return null;

    const settings = {
      nextArrow: ArrowRight,
      prevArrow: ArrowLeft,
      centerMode: true,
      className: 'product__lifestyle-photos-slider',
      dots: false,
      focusOnSelect: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    };

    const photoComp = (photo) => (
      <div key={photo.date} className="lifestyle-photo">
        {photo.type === 'image' ? (
          <img src={photo.imageSrc} />
        ) : (
          <video autoPlay controls loop muted>
            <source src={photo.videoSrc} type="video/mp4" />
          </video>
        )}
      </div>
    );

    const single = lifestylePhotos.length === 1;

    return (
      <div className={`product__lifestyle-photos`}>
        <h2 className="product__lifestyle-photos-title">See it in action</h2>

        {single ? (
          <div className="product__lifestyle-photos-slider lifestyle-photos-slider--single">
            {photoComp(lifestylePhotos[0])}
          </div>
        ) : (
          <Slider {...settings}>
            {lifestylePhotos.map((photo) => photoComp(photo))}
          </Slider>
        )}
      </div>
    );
  }
}

export default ProductLifestylePhotos;
