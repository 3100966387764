import React, { Component } from 'react';
import Slider from 'react-slick';

import ProductImage from '../../ProductImage';
import { getPimValue } from '../../../../utils/utils';
import { NextArrow, PrevArrow } from '../images/Arrows';

class ProductColors extends Component {
  render() {
    const { onClick, product, site } = this.props;

    const colorImages = {};
    for (const variant of product.variants) {
      if (colorImages[getPimValue(variant.colorName)]) continue;
      if (!variant.images[0]) continue;
      colorImages[getPimValue(variant.colorName)] = variant.images[0];
    }

    const images = Object.keys(colorImages).map((colorImageKey) => (
      <li
        className="color"
        key={colorImageKey}
        onClick={(e) => onClick(e, colorImageKey)}
      >
        <span className="color-image">
          <ProductImage
            image={{
              alt: `${getPimValue(product.name, site)} - ${colorImageKey}`,
              url: colorImages[colorImageKey]?.url,
            }}
            sizes={[{ query: '', containerSize: '150px' }]}
          />
        </span>
        <span className="color-text">
          {colorImageKey.split('/').join(' / ')}
        </span>
      </li>
    ));

    if (images.length === 0) return null;

    const settings = {
      className: 'colors-list',
      dots: false,
      infinite: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      slidesToScroll: 8,
      slidesToShow: 8,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToScroll: 3,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToScroll: 4,
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 5,
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 6,
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToScroll: 7,
            slidesToShow: 7,
          },
        },
      ],
    };

    return (
      <section className="product__colors">
        <div className="colors-wrapper">
          <Slider {...settings}>{images}</Slider>
        </div>
      </section>
    );
  }
}

export default ProductColors;
