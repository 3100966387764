import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import JsonLD from '../../_common/JsonLD';
import Media from '../../../models/slices/Media';
import Popup from '../../_common/Popup';
import ProductAnatomyIcons from './anatomy-icons-row/ProductAnatomyIcons';
import ProductColors from './colors-row/ProductColors';
import ProductCTA from '../product-cta/ProductCTA';
import ProductHeaderPaneOverlay from './ProductHeaderPaneOverlay';
import ProductImages from './images/ProductImages';
import ProductLifestylePhotos from './ProductLifestylePhotos';
import ProductSizeChart from './ProductSizeChart';
import ProductVariantSelector from '../ProductVariantSelector';
import Products from '../../../models/slices/Products';
import SiteLoader from '../../_common/SiteLoader';
import Slice from '../../slices/Slice';
import withKlaviyo from '../../../containers/plugins/withKlaviyo';
import withShopProduct from '../../../containers/plugins/withShopProduct';
import { getCmsValue, getPimValue } from '../../../utils/utils';

class Product extends Component {
  constructor(props) {
    super(props);

    this.productImagesRef = null;
    this.sizeChartRef = null;
    this.productImagesSkuIndexMap = {};

    this.onProductImagesLoad = this.onProductImagesLoad.bind(this);
    this.onReadMoreClick = this.onReadMoreClick.bind(this);
    this.onVariantSelectorLoad = this.onVariantSelectorLoad.bind(this);
    this.onVariantSelected = this.onVariantSelected.bind(this);
    this.onAddToCart = this.onAddToCart.bind(this);
    this.onHeaderPaneOverlayOpen = this.onHeaderPaneOverlayOpen.bind(this);
    this.onHeaderPaneOverlayClose = this.onHeaderPaneOverlayClose.bind(this);
    this.onColorClick = this.onColorClick.bind(this);
    this.onRelatedProductsClose = this.onRelatedProductsClose.bind(this);

    this.setVariantSelectorOption = null;

    this.state = {
      headerPaneOverlayContent: false,
      headerPaneOverlayOpen: false,
      relatedProductsOpen: false,
      selectedVariantSku: null,
      yotpoLoaded: false,
    };
  }

  componentDidMount() {
    const { fetchProduct, klaviyo, product } = this.props;

    fetchProduct();
    klaviyo.viewedProduct(product);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.product.baseShopId && !this.state.yotpoLoaded) {
      if (typeof window !== undefined) {
        try {
          window.yotpo?.refreshWidgets();
        } catch (err) {
          console.log('Could not refresh Yotpo widgets');
        }
      }
      this.setState((prevState) => ({
        ...prevState,
        yotpoLoaded: true,
      }));
    }
  }

  onProductImagesLoad(ref, skuIndexMap) {
    this.productImagesRef = ref;
    this.productImagesSkuIndexMap = skuIndexMap;
  }

  onReadMoreClick() {
    const { product, site } = this.props;

    const component = (
      <div
        dangerouslySetInnerHTML={{
          __html: getPimValue(product.longDescription, site),
        }}
      />
    );

    this.onHeaderPaneOverlayOpen(component);
  }

  onHeaderPaneOverlayOpen(component) {
    this.setState((prevState) => ({
      ...prevState,
      headerPaneOverlayContent: component,
      headerPaneOverlayOpen: true,
    }));
  }

  onHeaderPaneOverlayClose() {
    this.setState((prevState) => ({
      ...prevState,
      headerPaneOverlayOpen: false,
    }));
  }

  onVariantSelectorLoad(setVariantSelectorOption) {
    this.setVariantSelectorOption = setVariantSelectorOption;
  }

  onVariantSelected(selectedVariantSku) {
    this.setState((prevState) => ({
      ...prevState,
      selectedVariantSku: selectedVariantSku,
    }));

    const { product } = this.props;

    this.productImagesRef?.slickGoTo(
      this.productImagesSkuIndexMap[selectedVariantSku],
      true
    );
  }

  onAddToCart() {
    /*
    if (!this.relatedProductsRef) return;

    const getChildNode = (child, className) => {
      if (Array.from(child.stateNode.classList).includes(className)) {
        return child.stateNode;
      } else {
        return getChildNode(child.child, className);
      }
    };

    let screenSize = 'small';
    if (typeof window !== 'undefined') {
      screenSize =
        window.matchMedia && window.matchMedia('(max-width: 768px)').matches
          ? 'small'
          : 'large';
    }

    if (screenSize === 'large') {
      const relatedProductsSlice = getChildNode(
        this.relatedProductsRef._reactInternals.child,
        'slice--productsSlice'
      );

      this.setState((prevState) => ({
        ...prevState,
        relatedProductsOpen: true,
      }));
    }
    */
  }

  onColorClick(e, color) {
    const index = this.props.product.variableFields.reduce((acc, field, i) => {
      if (field.value === 'colorName') acc = i;
      return acc;
    }, 0);

    if (this.setVariantSelectorOption) {
      this.setVariantSelectorOption(color, 'colorName', index);
    }
  }

  onRelatedProductsClose() {
    this.setState((prevState) => ({
      ...prevState,
      relatedProductsOpen: false,
    }));
  }

  render() {
    const {
      breadcrumbs,
      imageMapProducts,
      jsonLd,
      product,
      relatedProducts,
      shopProductsLoading,
      site,
    } = this.props;

    const { selectedVariantSku } = this.state;

    const shopProductLoading = shopProductsLoading.includes(product.path);

    const selectedVariant =
      product.variants.find(
        (variant) => getPimValue(variant.sku, site) === selectedVariantSku
      ) || null;

    return (
      <article className="page product">
        <Helmet title={getPimValue(product.name, site)} />
        <JsonLD jsonLd={jsonLd} />
        <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

        <div className="product__wrapper">
          <div className="product__header-wrapper">
            <header className="product__header">
              <div className="product__header-pane header-pane--left">
                <ProductImages
                  product={product}
                  imageMapProducts={imageMapProducts}
                  onLoad={this.onProductImagesLoad}
                  productImagesRef={this.productImagesRef}
                  site={site}
                />
              </div>
              <div className="product__header-pane header-pane--right">
                <h1 className="product__title">
                  {getPimValue(product.name, site)}
                </h1>

                <div className="product__meta">
                  <div className="product__meta-series">
                    {getPimValue(product.series, site)}
                  </div>

                  {product.baseShopId && (
                    <div
                      className="yotpo bottomLine product__meta-rating"
                      data-yotpo-product-id={product.baseShopId}
                    />
                  )}
                </div>

                <div className="product__header-pane-content">
                  <ProductHeaderPaneOverlay
                    headerPaneOverlayContent={
                      this.state.headerPaneOverlayContent
                    }
                    headerPaneOverlayOpen={this.state.headerPaneOverlayOpen}
                    onHeaderPaneOverlayClose={this.onHeaderPaneOverlayClose}
                    site={site}
                  />

                  <div className="product__short-description">
                    {getPimValue(product.shortDescription, site)}
                    <span
                      className="short-description__read-more"
                      onClick={this.onReadMoreClick}
                    >
                      read more
                    </span>
                  </div>

                  {shopProductLoading ? (
                    <SiteLoader />
                  ) : (
                    <>
                      <ProductVariantSelector
                        onLoad={this.onVariantSelectorLoad}
                        onVariantSelected={this.onVariantSelected}
                        product={product}
                        site={site}
                        sizeChartFields={product.sizeChartFields}
                        urlInteraction
                      />

                      <ProductCTA
                        countries={this.props.countries}
                        product={product}
                        selectedVariant={selectedVariant}
                        site={site}
                      />
                    </>
                  )}
                </div>
              </div>
            </header>
          </div>

          {product.category.key === 'Hooks' && (
            <ProductAnatomyIcons product={product} site={site} />
          )}

          {product.category.key === 'Lures' && (
            <ProductColors
              onClick={this.onColorClick}
              product={product}
              site={site}
            />
          )}
        </div>

        <ProductLifestylePhotos product={product} />

        {product.sizeChartFields.length > 0 && (
          <ProductSizeChart
            product={product}
            site={site}
            onSizeChartRef={(ref) => (this.sizeChartRef = ref)}
          />
        )}

        {product.videos.length > 0 && (
          <Slice
            slice={
              new Media({
                _key: 'media',
                _type: 'mediaSlice',
                media: {
                  type: 'video',
                  videoType: 'youtube',
                  videoYoutube: product.videos[0]?.value || '',
                },
              })
            }
          />
        )}

        {product.baseShopId && (
          <div
            className="product__reviews yotpo yotpo-main-widget"
            data-product-id={product.baseShopId}
            data-price={product.variants[0]?.price}
            data-currency="USD"
            data-name={getPimValue(product.name)}
            data-url={typeof window !== 'undefined' ? window.location.href : ''}
            data-image-url={product.images[0]?.url || ''}
          />
        )}

        {relatedProducts.length > 0 && (
          <>
            <Slice
              ref={(ref) => (this.relatedProductsRef = ref)}
              slice={
                new Products(
                  {
                    _key: 'products',
                    _type: 'productsSlice',
                    multiRow: false,
                    title: {
                      ...site.strings.productPage__relatedProductsTitle,
                    },
                  },
                  site.uid,
                  { products: relatedProducts }
                )
              }
              site={site}
            />

            <Popup
              className="related-products-popup popup--no-width"
              headerContent={
                <span>
                  {getCmsValue(
                    site.strings.productPage__relatedProductsTitle,
                    site
                  )}
                </span>
              }
              onClose={this.onRelatedProductsClose}
              inverted={true}
              visible={this.state.relatedProductsOpen}
            >
              <Slice
                slice={
                  new Products(
                    {
                      _key: 'products',
                      _type: 'productsSlice',
                      multiRow: false,
                      title: {},
                    },
                    site.uid,
                    { products: relatedProducts }
                  )
                }
                site={site}
              />
            </Popup>
          </>
        )}
      </article>
    );
  }
}

export default compose(
  withKlaviyo,
  withShopProduct,
  connect((state) => ({
    shopProductsLoading: state.shopService.shopProductsLoading,
  }))
)(Product);
