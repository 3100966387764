import React, { Component } from 'react';
import Link from '../../../Link';

import { ArrowRight } from '../../../_common/Icons';
import { getPimValue } from '../../../../utils/utils';

class ProductImageMap extends Component {
  constructor(props) {
    super(props);

    this.getSpotData = this.getSpotData.bind(this);
    this.onImageMapClick = this.onImageMapClick.bind(this);

    this.state = {
      activePoint: null,
      activePointParent: null,
      d: {},
      spotData: {
        product: props.imageMapProducts[0],
      },
      tooltipOpen: false,
    };
  }

  getSpotData(sourceId) {
    const { imageMapProducts } = this.props;
    if (imageMapProducts.length === 0) return null;

    const product = imageMapProducts.find(
      (imageMapProduct) => imageMapProduct.sourceId === parseInt(sourceId)
    );

    return { product: product || null };
  }

  onImageMapClick(e, sourceId) {
    e.stopPropagation();

    if (sourceId) {
      const spotData = this.getSpotData(sourceId);
      const activePoint = e.target.getBoundingClientRect();
      const activePointParent = e.target.parentElement.getBoundingClientRect();

      const d = {
        containerWidth: activePointParent.width,
        containerHeight: activePointParent.height,
        labelWidth: this.labelRef?.width || 100,
        labelHeight: this.labelRef?.height || 34,
        pointTop: activePoint.top - activePointParent.top,
        pointRight:
          activePoint.right - activePointParent.right + activePointParent.width,
        pointBottom:
          activePoint.bottom -
          activePointParent.bottom +
          activePointParent.height,
        pointLeft: activePoint.left - activePointParent.left,
      };
      d.pointXCenter = (d.pointLeft + d.pointRight) / 2;
      d.pointYCenter = (d.pointTop + d.pointBottom) / 2;
      d.toLeft = d.pointXCenter > d.containerWidth - d.pointXCenter;
      d.toTop = d.pointYCenter > d.containerHeight - d.pointYCenter;
      d.toXEdge = d.toLeft ? d.pointXCenter : d.containerWidth - d.pointXCenter;
      d.toYEdge = d.toTop ? d.pointYCenter : d.containerHeight - d.pointYCenter;

      this.setState((prevState) => ({
        ...prevState,
        d: d,
        spotData: spotData,
        tooltipOpen: true,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        tooltipOpen: false,
      }));
    }
  }

  render() {
    const { imageMap, site } = this.props;
    const { d, spotData, tooltipOpen } = this.state;
    const product = spotData?.product;

    if (!imageMap || !imageMap?.spots) return null;

    const spots = imageMap.spots.map((spot, index) => (
      <circle
        key={index}
        className="image-map__spot"
        cx={spot.x}
        cy={spot.y}
        onClick={(e) => this.onImageMapClick(e, spot.sourceId)}
      />
    ));
    return (
      <div className="product__image-map">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1 1"
          xmlSpace="preserve"
          className="image-map__map"
          onClick={(e) => this.onImageMapClick(e)}
        >
          {spots}
        </svg>

        <div className={`point ${tooltipOpen ? 'point--visible' : ''}`}>
          <>
            <div
              className="point__line--vertical"
              style={{
                height: `${d.toYEdge / 2}px`,
                top: `${d.toTop ? d.toYEdge / 2 : d.pointYCenter}px`,
                bottom: `${d.toTop ? d.pointYCenter : d.toYEdge / 2}px`,
                left: `${d.pointXCenter}px`,
                transformOrigin: `${d.toTop ? 'bottom' : 'top'}`,
              }}
            ></div>

            <div
              className="point__line--horizontal"
              style={{
                width: `10px`,
                top: `${
                  d.toTop ? d.toYEdge / 2 : d.pointYCenter + d.toYEdge / 2
                }px`,
                right: `${
                  d.toLeft ? d.containerWidth - d.toXEdge : d.toXEdge / 2
                }px`,
                left: `${d.toLeft ? 'auto' : d.pointXCenter + 'px'}`,
                transformOrigin: `${d.toLeft ? 'right' : 'left'}`,
              }}
            ></div>

            <div
              className="point__label"
              ref={(ref) => (this.labelRef = ref)}
              style={{
                top: `${
                  d.toTop ? d.toYEdge / 2 - d.labelHeight + 'px' : 'auto'
                }`,
                right: `${
                  d.toLeft ? d.containerWidth - d.toXEdge + 10 + 'px' : 'auto'
                }`,
                bottom: `${
                  d.toTop
                    ? 'auto'
                    : d.containerHeight -
                      (d.pointYCenter + d.toYEdge / 2) -
                      d.labelHeight +
                      'px'
                }`,
                left: `${d.toLeft ? 'auto' : d.pointXCenter + 10 + 'px'}`,
                transformOrigin: `${d.toLeft ? 'right' : 'left'}`,
              }}
            >
              <Link className="point__title" site={site} to={product}>
                <span>{product && getPimValue(product.series, site)}</span>
                <span>{ArrowRight}</span>
              </Link>

              <div className="point__subtitle">
                {product && getPimValue(product.name, site)}
              </div>
            </div>
          </>
        </div>
      </div>
    );
  }
}

export default ProductImageMap;
