import React, { useEffect, useState } from 'react';

const TableHeaderData = ({ column, index, onClick, sortKey }) => {
  const isSortKey = sortKey.key === index;

  const classNames = [
    isSortKey ? 'sort-key' : '',
    isSortKey && sortKey.reversed ? 'sort-key--reversed' : '',
  ].join(' ');

  return (
    <th className={classNames} onClick={() => onClick(column.value)}>
      {column.title}
    </th>
  );
};

const TableRow = ({ row }) => {
  return (
    <tr>
      {row.map((column, i) => (
        <td key={`row-column-${i}`}>{column}</td>
      ))}
    </tr>
  );
};

const Table = ({ data, freezeFirstColumn, freezeFirstRow }) => {
  if (!data?.columns || !data?.rows) return null;

  const [sortKey, setSortKey] = useState({ key: 1, reversed: false });

  const onTableHeaderClick = (column) => {
    const newSortKey = { key: column };
    if (column === sortKey.key) newSortKey.reversed = !sortKey.reversed;
    setSortKey(newSortKey);
  };

  const isNumber =
    data.rows.filter((row) => parseInt(row[sortKey.key])).length ===
    data.rows.length;

  let rows = data.rows.sort((a, b) =>
    isNumber
      ? a[sortKey.key] - b[sortKey.key]
      : a[sortKey.key].toString().localeCompare(b[sortKey.key].toString())
  );

  if (data.columns[sortKey.key].value === 'hookSize') {
    const aughts = rows.filter((row) => row[sortKey.key].indexOf('/') !== -1);
    const normals = rows.filter((row) => row[sortKey.key].indexOf('/') === -1);

    aughts.sort((a, b) => {
      const valA = a[sortKey.key].replace(/\//, '');
      const valB = b[sortKey.key].replace(/\//, '');
      return valA - valB;
    });

    rows = [...aughts.reverse(), ...normals].reverse();
  } else if (data.columns[sortKey.key].sort) {
    rows = rows.sort(data.columns[sortKey.key].sort(sortKey));
  }

  if (sortKey.reversed) rows = [...rows].reverse();

  const classNames = [
    freezeFirstColumn ? 'table--freeze-first-col' : '',
    freezeFirstRow ? 'table--freeze-first-row' : '',
  ].join(' ');

  return (
    <div className="table-wrapper">
      <table className={`table ${classNames}`}>
        <thead>
          <tr>
            {data.columns.map((column, i) => (
              <TableHeaderData
                column={column}
                index={i}
                key={`header-column-${column.value}`}
                onClick={() => onTableHeaderClick(i)}
                sortKey={sortKey}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <TableRow key={`body-row-${i}`} row={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
