import React, { Component } from 'react';

import { getPimValue } from '../../../utils/utils';

class ProductSizeChart extends Component {
  constructor(props) {
    super(props);

    this.resolveSizeChartColumns = this.resolveSizeChartColumns.bind(this);
    this.onSizeChartSort = this.onSizeChartSort.bind(this);
    this.onSizeChartRowClick = this.onSizeChartRowClick.bind(this);

    this.initialState = {
      sizeChartHeader: [],
      sizeChartReverseSort: false,
      sizeChartRowClicked: null,
      sizeChartRows: [],
      sizeChartSortIndex: 0,
    };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.resolveSizeChartColumns();
  }

  resolveSizeChartColumns() {
    const { product, site } = this.props;
    const { sizeChartFields, variants } = product;

    const sizeChartHeader = [];
    const sizeChartRows = [];

    for (const sizeChartField of sizeChartFields) {
      for (const variant of variants) {
        if (
          getPimValue(variant[sizeChartField.value], site).indexOf(' | ') !== -1
        ) {
          sizeChartField.split = true;
          break;
        }
      }
    }

    for (const sizeChartField of sizeChartFields) {
      if (sizeChartField.split) {
        sizeChartHeader.push(sizeChartField, sizeChartField);
      } else {
        sizeChartHeader.push(sizeChartField);
      }
    }

    for (const variant of variants) {
      const sizeChartRow = [];

      for (const sizeChartField of sizeChartFields) {
        if (sizeChartField.split) {
          sizeChartRow.push(
            getPimValue(variant[sizeChartField.value], site).split(' | ')[0]
          );
          sizeChartRow.push(
            getPimValue(variant[sizeChartField.value], site).split(' | ')[1]
          );
        } else {
          sizeChartRow.push(getPimValue(variant[sizeChartField.value], site));
        }
      }

      sizeChartRows.push(sizeChartRow);
    }

    this.setState((prevState) => ({
      ...prevState,
      sizeChartHeader: sizeChartHeader,
      sizeChartRows: sizeChartRows,
    }));
  }

  onSizeChartSort(sortIndex) {
    this.setState((prevState) => ({
      ...prevState,
      sizeChartReverseSort:
        prevState.sizeChartSortIndex === sortIndex
          ? !prevState.sizeChartReverseSort
          : false,
      sizeChartSortIndex: sortIndex,
    }));
  }

  onSizeChartRowClick(sizeChartRow) {
    this.setState((prevState) => ({
      ...prevState,
      sizeChartRowClicked:
        sizeChartRow === prevState.sizeChartRowClicked ? null : sizeChartRow,
    }));
  }

  render() {
    const {
      sizeChartHeader,
      sizeChartReverseSort,
      sizeChartRowClicked,
      sizeChartRows,
      sizeChartSortIndex,
    } = this.state;

    let sizeChartRowsSorted;

    if (sizeChartSortIndex === 0) {
      sizeChartRowsSorted = sizeChartRows;
    } else {
      sizeChartRowsSorted = sizeChartRows.sort((a, b) =>
        a[sizeChartSortIndex]?.localeCompare(b[sizeChartSortIndex])
      );
    }

    if (sizeChartReverseSort) sizeChartRowsSorted.reverse();

    return (
      <div
        className="product__size-chart"
        ref={(ref) => this.props.onSizeChartRef(ref)}
      >
        <table>
          <thead>
            <tr>
              {sizeChartHeader.map((sizeChartColumn, i) => (
                <th
                  key={`${sizeChartColumn.value}-${i}`}
                  className={`${i === sizeChartSortIndex ? 'sort-key' : ''} ${
                    i === sizeChartSortIndex && sizeChartReverseSort
                      ? 'sort-key--reversed'
                      : ''
                  }`}
                  onClick={(e) => this.onSizeChartSort(i)}
                >
                  {sizeChartColumn.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sizeChartRowsSorted.map((sizeChartRow, i) => (
              <tr
                key={`${sizeChartRow.toString()}-${i}`}
                className={`${
                  sizeChartRowClicked === sizeChartRow.toString()
                    ? 'clicked'
                    : ''
                }`}
                onClick={(e) =>
                  this.onSizeChartRowClick(sizeChartRow.toString())
                }
              >
                {sizeChartRow.map((sizeChartColumn, i) => (
                  <td key={`${sizeChartColumn?.toString()}-${i}`}>
                    {sizeChartColumn}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProductSizeChart;
