import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ProductVariantSelectorTable from './ProductVariantSelectorTable';
import Select from '../_common/Select';
import withCart from '../../containers/plugins/withCart';
import { getCmsValue, getPimValue } from '../../utils/utils';
import { ListView } from '../_common/Icons';

class ProductVariantSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialUrlHandled: false,
      initialLoadHandled: false,
      optionSets: props.product.variableFields.reduce(
        (acc, { value: optionSetKey }) => {
          acc[optionSetKey] = new Set();
          return acc;
        },
        {}
      ),
      selectorTypes: {
        updated: false,
        types: props.product.variableFields.reduce(
          (acc, { value: optionSetKey }) => ({
            ...acc,
            [optionSetKey]: 'BUTTONS',
          }),
          {}
        ),
      },
      selectedOptions: {},
      selectedVariants: props.product.variants,
      table: false,
    };

    this.setSelectorTypes = this.setSelectorTypes.bind(this);
    this.setSelectedOptionsFromUrl = this.setSelectedOptionsFromUrl.bind(this);
    this.setUrlFromSelectedOptions = this.setUrlFromSelectedOptions.bind(this);
    this.setDefaultSelectedOptions = this.setDefaultSelectedOptions.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.setSelectedVariants = this.setSelectedVariants.bind(this);
    this.setOptionSets = this.setOptionSets.bind(this);
    this.onTableToggle = this.onTableToggle.bind(this);
  }

  componentDidMount() {
    const { onLoad } = this.props;

    this.setSelectedVariants();

    onLoad(this.onOptionChange);
  }

  componentDidUpdate() {
    const { urlInteraction } = this.props;
    const {
      initialLoadHandled,
      initialUrlHandled,
      selectedVariants,
      selectorTypes,
    } = this.state;
    const selectedVariant = selectedVariants.length === 1;

    if (urlInteraction && !initialUrlHandled) {
      this.setSelectedOptionsFromUrl();
    }

    if (urlInteraction && initialUrlHandled) {
      this.setUrlFromSelectedOptions();
    }

    if (
      urlInteraction &&
      initialUrlHandled &&
      !initialLoadHandled &&
      !selectedVariant
    ) {
      this.setDefaultSelectedOptions();
    }

    if (!selectorTypes.updated) this.setSelectorTypes();
  }

  setSelectorTypes() {
    const { optionSets, selectorTypes } = this.state;

    const variantSelectorPane = document.querySelector('.variant-selector');
    const newSelectorTypes = { types: {} };

    for (const optionSetKey of Object.keys(optionSets)) {
      const optionsPane = document.querySelector('#' + optionSetKey);
      if (!optionsPane) return;

      if (optionsPane.scrollWidth > variantSelectorPane.clientWidth) {
        newSelectorTypes.types[optionSetKey] = 'SELECT';
      } else if (selectorTypes.types[optionSetKey]) {
        newSelectorTypes.types[optionSetKey] = 'BUTTONS';
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      selectorTypes: {
        updated: true,
        ...newSelectorTypes,
      },
    }));
  }

  setSelectedOptionsFromUrl() {
    const { variableFields } = this.props.product;
    const { selectedOptions } = this.state;

    const newSelectedOptions = { ...selectedOptions };

    const query = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );

    for (let i = 0; i < variableFields.length; i++) {
      const optionSetKey = variableFields[i].value;

      if (query[optionSetKey] && !newSelectedOptions[optionSetKey]) {
        newSelectedOptions[optionSetKey] = query[optionSetKey];
        this.onOptionChange(query[optionSetKey], optionSetKey, i);
        break;
      }

      if (i === variableFields.length - 1) {
        this.setState((prevState) => ({
          ...prevState,
          initialUrlHandled: true,
        }));
      }
    }
  }

  setUrlFromSelectedOptions() {
    const { selectedOptions } = this.state;

    const notNullSelectedOptions = Object.fromEntries(
      Object.entries(selectedOptions).filter(([k, v]) => v != null)
    );

    const newQuery = new URLSearchParams(notNullSelectedOptions);
    window.history.replaceState('', '', '?' + newQuery);
  }

  setDefaultSelectedOptions() {
    const { site } = this.props;
    const { variableFields, variants } = this.props.product;
    const { selectedOptions } = this.state;

    const availableVariants = variants.filter((variant) => variant.available);
    const sortedVariants = availableVariants.sort((a, b) =>
      parseFloat(a.price) > parseFloat(b.price) ? 1 : -1
    );
    const defaultVariant = sortedVariants[0] || variants[0];

    if (!defaultVariant) return;

    const newSelectedOptions = { ...selectedOptions };

    for (let i = 0; i < variableFields.length; i++) {
      const optionSetKey = variableFields[i].value;
      const value = getPimValue(defaultVariant[optionSetKey], site);

      if (value && !newSelectedOptions[optionSetKey]) {
        newSelectedOptions[optionSetKey] = value;
        this.onOptionChange(value, optionSetKey, i);
        break;
      }

      if (i === variableFields.length - 1) {
        this.setState((prevState) => ({
          ...prevState,
          initialLoadHandled: true,
        }));
      }
    }
  }

  onOptionChange(selectedOption, changedOptionSetKey, changedOptionSetIndex) {
    const { variableFields } = this.props.product;
    const { selectedOptions } = this.state;
    const newSelectedOptions = { ...selectedOptions };

    newSelectedOptions[changedOptionSetKey] = selectedOption;
    for (let i = changedOptionSetIndex + 1; i < variableFields.length; i++) {
      newSelectedOptions[variableFields[i].value] = null;
    }

    this.setSelectedVariants(changedOptionSetIndex, newSelectedOptions);
  }

  setSelectedVariants(changedOptionSetIndex = 0, newSelectedOptions = {}) {
    const { onVariantSelected, site } = this.props;
    const { variants, variableFields } = this.props.product;
    let newSelectedVariants = [...variants];

    for (const { value: optionSetKey } of variableFields) {
      newSelectedVariants = newSelectedVariants.filter((variant) => {
        if (!newSelectedOptions[optionSetKey]) {
          return true;
        } else {
          return (
            newSelectedOptions[optionSetKey] ===
            getPimValue(variant[optionSetKey], site)
          );
        }
      });
    }

    if (
      changedOptionSetIndex === variableFields.length - 1 &&
      newSelectedVariants.length > 1
    ) {
      newSelectedVariants = this.concatDuplicates(newSelectedVariants);
    }

    if (newSelectedVariants.length === 1) {
      onVariantSelected(newSelectedVariants[0].sku.value);
    } else {
      onVariantSelected(null);
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        selectedVariants: newSelectedVariants,
      }),
      () => this.setOptionSets(changedOptionSetIndex, newSelectedOptions)
    );
  }

  concatDuplicates(newSelectedVariants) {
    const { product } = this.props;

    const concatProps = (variant) => {
      return product.variableFields
        .map((field) => getPimValue(variant[field.value]))
        .join('');
    };

    let match = true;
    for (const variant of newSelectedVariants) {
      const variantProps = concatProps(variant);

      for (const compareVariant of newSelectedVariants) {
        const compareVariantProps = concatProps(compareVariant);
        if (variantProps === compareVariantProps) match = true;
        else match = false;

        if (!match) break;
      }
      if (!match) break;
    }

    if (!match) return newSelectedVariants;

    const priceSorted = newSelectedVariants
      .sort((a, b) => a.price - b.price)
      .filter((variant) => variant.price);
    const available = priceSorted.find((variant) => variant.available);

    if (available) return [available];
    else return [priceSorted[0]];
  }

  setOptionSets(changedOptionSetIndex = 0, newSelectedOptions = {}) {
    const { site } = this.props;
    const { variableFields } = this.props.product;
    const { optionSets, selectedVariants } = this.state;
    const newOptionSets = { ...optionSets };

    for (let i = changedOptionSetIndex + 1; i < variableFields.length; i++) {
      newOptionSets[variableFields[i].value].clear();
    }

    for (const { value: optionSetKey } of variableFields) {
      selectedVariants.forEach((variant) => {
        const value = getPimValue(variant[optionSetKey], site);
        value && newOptionSets[optionSetKey].add(value);
      });

      if (newOptionSets[optionSetKey].size === 1) {
        newSelectedOptions[optionSetKey] = [...newOptionSets[optionSetKey]][0];
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      optionSets: newOptionSets,
      selectedOptions: newSelectedOptions,
    }));
  }

  onTableToggle() {
    this.setState((prevState) => ({
      ...prevState,
      table: !this.state.table,
    }));
  }

  render() {
    const { product, site } = this.props;
    const { variableFields } = this.props.product;
    const { optionSets, selectedOptions, selectorTypes } = this.state;

    const styles = (styles, { data, selectProps: { property } }) => {
      const availableVariants = [];

      const optionKeys = Object.keys(selectedOptions)
        .slice(0, Object.keys(selectedOptions).indexOf(property))
        .filter((optionKey) => selectedOptions[optionKey]);

      for (const variant of product.variants) {
        const matchingVariant = true;
        for (const optionKey of optionKeys) {
          if (
            getPimValue(variant[optionKey], site) !== selectedOptions[optionKey]
          ) {
            matchingVariant = false;
            break;
          }
        }
        if (matchingVariant) {
          availableVariants.push(variant);
        }
      }

      const available = availableVariants.find(
        (variant) =>
          getPimValue(variant[property], site) === data.value &&
          variant.available === true &&
          variant.price !== '88888'
      );

      return {
        ...styles,
        ':before': {
          backgroundColor: available ? '#259b24' : '#e51c23',
        },
      };
    };

    const selects = variableFields.map(
      ({ value: optionSetKey, title: label }, i) => {
        const sortedOptions = (key, options) => {
          switch (key) {
            case 'hookSize':
              const aughts = options.filter(
                (option) => option.value.indexOf('/') !== -1
              );
              const normal = options.filter(
                (option) => option.value.indexOf('/') === -1
              );
              const sortedAughts = aughts.sort((a, b) =>
                a.value < b.value ? 1 : -1
              );
              const sortedNormal = normal.sort((a, b) =>
                parseInt(a.value) < parseInt(b.value) ? -1 : 1
              );
              return sortedAughts.concat(sortedNormal);
            default:
              return options;
          }
        };

        const options = sortedOptions(
          optionSetKey,
          [...optionSets[optionSetKey]].map((option) => ({
            label: option,
            value: option,
          }))
        );

        const value = {
          label: selectedOptions[optionSetKey],
          value: selectedOptions[optionSetKey],
        };

        const isHidden = options.length === 0;
        const isDisabled = options.length === 1;

        if (isHidden) return null;

        const classNames = [
          isDisabled ? 'selector-wrapper--is-disabled' : '',
          !value.value ? 'selector-wrapper--not-selected' : '',
        ].join(' ');

        return (
          <div
            className={`variant-selector__selector-wrapper ${classNames}`}
            id={optionSetKey}
            key={optionSetKey}
          >
            <span className="variant-selector__label">{label}</span>
            {selectorTypes.types &&
            selectorTypes.types[optionSetKey] === 'SELECT' ? (
              <Select
                placeholder={getCmsValue(
                  site.strings.productVariantSelector__selectPlaceholder,
                  site
                )}
                onChange={(selectedOption) =>
                  this.onOptionChange(selectedOption.value, optionSetKey, i)
                }
                options={options}
                value={value.value ? value : null}
                isDisabled={isDisabled}
                isSearchable={false}
                property={optionSetKey}
                styles={{ option: styles }}
              />
            ) : (
              <div className="variant-selector__option-buttons">
                {options.map((option) => (
                  <button
                    key={option.value}
                    disabled={options.length === 1 ? true : false}
                    className={`option-button ${
                      value.value === option.value
                        ? 'option-button--selected'
                        : ''
                    }`}
                    onClick={() =>
                      this.onOptionChange(option.value, optionSetKey, i)
                    }
                  >
                    <span
                      style={
                        styles(
                          {},
                          {
                            data: {
                              label: option.value,
                              value: option.value,
                            },
                            selectProps: { property: optionSetKey },
                          }
                        )[':before']
                      }
                    />
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      }
    );

    return (
      <div className="variant-selector">
        <span
          className="variant-selector__table-toggle"
          onClick={this.onTableToggle}
        >
          {ListView}
          <span>List View</span>
        </span>
        {selects}
        <ProductVariantSelectorTable
          cart={this.props.cart}
          onClose={this.onTableToggle}
          product={product}
          site={site}
          variableFields={variableFields}
          visible={this.state.table}
        />
      </div>
    );
  }
}

export default compose(
  withCart,
  connect((state) => ({
    cart: state.shopService.cart,
  }))
)(ProductVariantSelector);
