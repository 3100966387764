import * as Icons from './anatomyIcons';

export default {
  /** HOOK CVLS */
  hookPoint: {
    AlphaPoint: { text: '', icon: [Icons.AP, Icons.HookPointNeedlePoint] },
    Classic: { text: '', icon: [Icons.HookPointClassic] },
    KnifeEdge: { text: '', icon: [Icons.HookPointKnifeEdge] },
    SlimPoint: { text: '', icon: [Icons.UP, Icons.HookPointNeedlePoint] },
    UltraPoint: { text: '', icon: [Icons.UP, Icons.HookPointNeedlePoint] },
  },
  hookBarbType: {
    Barbless: { text: '', icon: [Icons.HookPointBarbless] },
    Microbarb: { text: '', icon: [Icons.HookPointMicroBarb] },
    LargeBarb: { text: '', icon: [null] },
    SmallBarb: { text: '', icon: [Icons.HookPointMicroBarb] },
  },
  hookSubPoint: {
    Beak: { text: '', icon: [Icons.HookPointBeak] },
    Dublin: { text: '', icon: [Icons.HookPointDublin] },
    Hollow: { text: '', icon: [Icons.HookPointHollow] },
  },
  hookSubShank: {
    Inline: { text: '', icon: [Icons.InLine] },
    OffsetKirbed: { text: '', icon: [Icons.Kirbed] },
    OffsetReversed: { text: '', icon: [Icons.Reversed] },
  },
  hookShank: {
    AlphaGrip: { text: '', icon: [null] },
    Baitholderbarbs: { text: '', icon: [Icons.HookShankALLBaitholder] },
    GripPin: { text: '', icon: [null] },
    KinkShank: { text: '', icon: [Icons.HookShankKinkShank] },
    OffsetShank: { text: '', icon: [Icons.HookShankOffsetShank] },
    Regular: { text: '', icon: [null] },
    ZBend: { text: '', icon: [Icons.HookShankZBend] },
  },
  hookWireStrength: {
    Standard: { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '1xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '2xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '3xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '4xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '5xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '6xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '7xStrong': { text: '', icon: [Icons.HookWireStrengthALLStrong] },
    '1xFine': { text: '', icon: [Icons.HookWireStrengthALLFine] },
    '2xFine': { text: '', icon: [Icons.HookWireStrengthALLFine] },
    '3xFine': { text: '', icon: [Icons.HookWireStrengthALLFine] },
    '4xFine': { text: '', icon: [Icons.HookWireStrengthALLFine] },
    '5xFine': { text: '', icon: [Icons.HookWireStrengthALLFine] },
  },
  hookLength: {
    Standard: { text: '', icon: [Icons.HookLengthALLLong] },
    '5XShort': { text: '', icon: [Icons.HookLengthALLShort] },
    '4xShort': { text: '', icon: [Icons.HookLengthALLShort] },
    '3xShort': { text: '', icon: [Icons.HookLengthALLShort] },
    '2xShort': { text: '', icon: [Icons.HookLengthALLShort] },
    '1xShort': { text: '', icon: [Icons.HookLengthALLShort] },
    '1xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '2xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '3xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '4xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '5xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '6xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '7xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '8xLong': { text: '', icon: [Icons.HookLengthALLLong] },
    '9xLong': { text: '', icon: [Icons.HookLengthALLLong] },
  },
  hookWire: {
    Forged: { text: '', icon: [Icons.HookWireForged] },
    Round: { text: '', icon: [null] },
    Triangle: { text: '', icon: [Icons.HookWireTriangle] },
  },
  hookEyeTurn: {
    Regular: { text: '', icon: [null] },
    UpEye: { text: '', icon: [null] },
    DownEye: { text: '', icon: [null] },
    Inline: { text: '', icon: [null] },
    InlineTreble: { text: '', icon: [null] },
  },
  hookEyeAngle: {
    90: { text: '', icon: [Icons.HookShankALLJigEyeBend] },
    60: { text: '', icon: [Icons.HookShankALLJigEyeBend] },
    45: { text: '', icon: [Icons.HookShankALLJigEyeBend] },
    30: { text: '', icon: [Icons.HookShankALLJigEyeBend] },
    28: { text: '', icon: [Icons.HookShankALLJigEyeBend] },
  },
  hookEye: {
    ActionRing: { text: '', icon: [Icons.HookEyeActionRing] },
    Brazed: { text: '', icon: [Icons.HookEyeBrazed] },
    LargeRinged: { text: '', icon: [Icons.HookEyeLargeRinged] },
    Looped: { text: '', icon: [Icons.HookEyeLooped] },
    LoopedTaperedRing: { text: '', icon: [Icons.HookEyeLooped] },
    NeedleEye: { text: '', icon: [Icons.HookEyeNeedleEye] },
    Open: { text: '', icon: [Icons.HookEyeOpenRing] },
    OpenShank: { text: '', icon: [Icons.HookEyeOpenShank] },
    Ringed: { text: '', icon: [Icons.HookEyeRinged] },
    Spade: { text: '', icon: [Icons.HookEyeSpade] },
    TaperedRing: { text: '', icon: [Icons.HookEyeTaperedRing] },
  },

  /** HOOK BOOLEANS */
  hookWeighted: {
    true: { text: '', icon: [null] },
  },
  hookWideGap: {
    true: { text: '', icon: [null] },
  },

  /** OTHERS */
  hookTrademark: {
    GripPin: { text: '', icon: [Icons.GripPin] },
    SlowSuperDeath: { text: '', icon: [Icons.SlowDeath] },
  },
  trademark: {
    AlphaSpring: { text: 'AlphaSpring', icon: [Icons.AlphaSpring] },
  },
  weedless: {
    true: { text: '', icon: [null] },
  },
};
