import React, { Component } from 'react';

import { Close } from '../../_common/Icons';

class ProductHeaderPaneOverlay extends Component {
  constructor(props) {
    super(props);

    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideClick);
  }

  onOutsideClick(e) {
    const { onHeaderPaneOverlayClose } = this.props;

    if (!this.ref || this.ref.contains(e.target)) return;

    onHeaderPaneOverlayClose();
  }

  render() {
    const {
      headerPaneOverlayContent,
      headerPaneOverlayOpen,
      onHeaderPaneOverlayClose,
    } = this.props;

    return (
      <section
        className={`product__header-pane-overlay ${
          headerPaneOverlayOpen ? 'header-pane-overlay--active' : ''
        }`}
        ref={(ref) => (this.ref = ref)}
      >
        <header
          className="header-pane-overlay__header"
          onClick={onHeaderPaneOverlayClose}
        >
          <span className="header-pane-overlay__header-close-text"></span>
          <span className="header-pane-overlay__header-close-button">
            {Close}
          </span>
        </header>
        {headerPaneOverlayContent}
      </section>
    );
  }
}

export default ProductHeaderPaneOverlay;
